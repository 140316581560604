/**
 * @file
 *
 * this file contains the component which controls all preferences.
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import {
  Alert,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { MdClose, MdHelpOutline, MdOpenInNew, MdSettings } from 'react-icons/md';

import { getSystemConfigurationAndPreferences, navigateToSystems } from 'utils';
import {
  DATAFLOW_PREFERENCES,
  HELP_PAGES,
  PAGE_SIZE_DEFAULTS,
  PERMISSION_CODES,
} from '../constants';
import { reportViewStateHandlers } from 'state/reportView';
import { DOM_IDS, SHEET_NAMING_CONVENTION } from './ReportViewComponents/constants';
import { useCreateExportJob } from 'hooks/useCreateExportJob';
import { preferencesStateHandlers, usePreferencesState } from 'data/dataflowPreferences';
import { useConnectionsAndSystems } from 'data/connectionsAndSystems';
import { getHelpPageLink } from 'service';

export default function DataflowPreferences({ connection, close, tenant }) {
  const { tenant_id: tenantId, user_system_id: userSystemId } = connection;

  const {
    updateIsPasswordMandatory,
    updateSelectAllFields,
    updatePageSize,
    updateShowExternalCodesInTable,
  } = preferencesStateHandlers;

  const {
    sheetNameAccessor,
    handleSheetNameChange,
    showPicklistValues: showPicklistValuesInExcel,
    handleShowPicklistValues: handleShowPicklistValuesInExcel,
  } = useCreateExportJob();

  const { queryPreferences, exportPreferences } = usePreferencesState();

  const { isPasswordMandatory } = exportPreferences;
  const {
    showExternalCodes: showExternalCodesInTable,
    pageSize,
    selectAllFields,
  } = queryPreferences;

  // local page size is essential for handling error related scenarios
  const [localPageSize, setLocalPageSize] = useState(pageSize);

  const { refetchSystems } = useConnectionsAndSystems();

  const [isPicklistConfigured, setIsPicklistConfigured] = useState(true);

  const canUserManagePreferences = useMemo(
    () =>
      tenant?.permissions.some(
        ({ permission_code }) => permission_code === PERMISSION_CODES.MANAGE_SYSTEM
      ),
    [tenant?.permissions]
  );

  const handleOpenPreferencesHelpPage = useCallback((event) => {
    const preferenceType = event.target.parentElement.id;
    let pageUrl;
    switch (preferenceType) {
      case DOM_IDS.EXPORT_PREFERENCES_HELP_BTN:
        pageUrl = getHelpPageLink(HELP_PAGES.EXPORT_PREFERENCES);
        break;
      case DOM_IDS.QUERY_PREFERENCES_HELP_BTN:
        pageUrl = getHelpPageLink(HELP_PAGES.QUERY_PREFERENCES);
        break;
      default:
        pageUrl = getHelpPageLink(HELP_PAGES.DATAFLOW_HOME);
    }

    window.open(pageUrl, '_blank', 'noopener,noreferrer');
  }, []);

  const handlePageSizeChange = useCallback(
    (event) => {
      const { handleUpdateTopValue } = reportViewStateHandlers;
      const newPageSize = event.target.value;
      setLocalPageSize(newPageSize);

      if (
        newPageSize >= PAGE_SIZE_DEFAULTS.LOWER_LIMIT &&
        newPageSize <= PAGE_SIZE_DEFAULTS.UPPER_LIMIT
      ) {
        setLocalPageSize(Number(newPageSize));
        updatePageSize(Number(newPageSize));
        handleUpdateTopValue(Number(newPageSize));
      }
    },
    [updatePageSize]
  );

  const handleSelectAllfieldsChange = useCallback(
    (event) => {
      const shouldSelectAllfields = event.target.checked;
      updateSelectAllFields(shouldSelectAllfields, connection);
    },
    [updateSelectAllFields, connection]
  );

  const handlePasswordPreferenceChange = useCallback(
    (event) => {
      const isPasswordRequired = event.target.checked;
      updateIsPasswordMandatory(isPasswordRequired, connection);
    },
    [connection, updateIsPasswordMandatory]
  );

  const handleShowExternalCodesInTable = useCallback(
    (event) => {
      const shouldShowExternalCodesInTable = event.target.checked;
      updateShowExternalCodesInTable(shouldShowExternalCodesInTable, connection);
    },
    [connection, updateShowExternalCodesInTable]
  );

  useEffect(() => {
    const refreshSystemConfigState = async () => {
      const userSystems = await refetchSystems();

      const { picklist, allPreferences } = getSystemConfigurationAndPreferences(
        userSystems.data,
        userSystemId
      );

      const passwordPreference =
        allPreferences?.[DATAFLOW_PREFERENCES.ENCRYPT_EXPORTED_FILE.KEY]?.is_enabled;
      const showAllFieldsPreference =
        allPreferences?.[DATAFLOW_PREFERENCES.SELECT_ALL_FIELDS.KEY]?.is_enabled;

      updateIsPasswordMandatory(passwordPreference, connection);
      updateSelectAllFields(showAllFieldsPreference, connection);
      setIsPicklistConfigured(Boolean(picklist));
    };

    refreshSystemConfigState();
  }, [connection, refetchSystems, updateIsPasswordMandatory, updateSelectAllFields, userSystemId]);

  const navigateToSystem = useCallback(() => {
    navigateToSystems(tenantId, userSystemId);
  }, [tenantId, userSystemId]);

  return (
    <Stack>
      <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <span>
            <MdSettings fontSize={14} style={{ color: '#354A5F', marginTop: 3 }} />
          </span>
          <Typography>
            <strong>Settings</strong>
          </Typography>
        </Stack>
        <Stack>
          <IconButton size="small" onClick={close}>
            <MdClose style={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      </Stack>
      {!isPicklistConfigured && (
        <Alert severity="warning">
          Picklist is not configured in the linked system, the data will lack external codes.
        </Alert>
      )}
      {!canUserManagePreferences && (
        <Alert severity="warning">
          You do not have neccessary permissions to toggle certain preferences
        </Alert>
      )}

      {/* Export preferences */}
      <Stack direction="row" spacing={0.5} alignItems={'center'} mt={2}>
        <Typography>
          <strong> Export Preferences</strong>
        </Typography>
        <Tooltip title={'Click to know more'}>
          <IconButton
            variant="outlined"
            size="small"
            onClick={handleOpenPreferencesHelpPage}
            id={DOM_IDS.EXPORT_PREFERENCES_HELP_BTN}
          >
            <MdHelpOutline fontSize={16} style={{ color: '#354A5F' }} />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack spacing={1} mt={1}>
        <PreferenceOption
          value={showPicklistValuesInExcel}
          onChangeHandler={handleShowPicklistValuesInExcel}
          label="Show External Codes"
          type="switch"
        />
        <PreferenceOption
          value={isPasswordMandatory}
          onChangeHandler={handlePasswordPreferenceChange}
          label="Is Password Mandatory"
          type="switch"
          disabled={!canUserManagePreferences}
        />
        <PreferenceOption
          label="Sheet naming convention"
          type="select"
          value={sheetNameAccessor}
          onChangeHandler={handleSheetNameChange}
          selectOptions={SHEET_NAMING_CONVENTION}
        />
      </Stack>

      {/* Query preferences */}
      <Stack direction="row" spacing={0.5} alignItems={'center'} mt={4}>
        <Typography>
          <strong> Query Preferences</strong>
        </Typography>
        <Tooltip title={'Click to know more'}>
          <IconButton
            variant="outlined"
            size="small"
            onClick={handleOpenPreferencesHelpPage}
            id={DOM_IDS.QUERY_PREFERENCES_HELP_BTN}
          >
            <MdHelpOutline fontSize={16} style={{ color: '#354A5F' }} />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack spacing={1} mt={1}>
        <PreferenceOption
          value={showExternalCodesInTable}
          onChangeHandler={handleShowExternalCodesInTable}
          label="Show External Codes"
          type="switch"
        />
        <PreferenceOption
          value={localPageSize}
          onChangeHandler={handlePageSizeChange}
          label="Page size"
          type="text"
          textFieldProps={{
            inputProps: {
              min: PAGE_SIZE_DEFAULTS.LOWER_LIMIT,
              max: PAGE_SIZE_DEFAULTS.UPPER_LIMIT,
            },
            InputProps: { sx: { height: 25, fontSize: 12 } },
            sx: {
              width: 80,
              '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                {
                  opacity: 1, // Keeps spinner buttons visible at all times
                },
            },
            helperText: `${PAGE_SIZE_DEFAULTS.LOWER_LIMIT} - ${PAGE_SIZE_DEFAULTS.UPPER_LIMIT}`,
            validator: isPageSizeOutOfBounds,
          }}
        />
        <PreferenceOption
          value={selectAllFields}
          onChangeHandler={handleSelectAllfieldsChange}
          label="Select all fields"
          type="switch"
          disabled={!canUserManagePreferences}
        />
      </Stack>

      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mt={4}>
        <Typography width={100} align="left">
          Open <strong>{connection?.name}</strong> System
        </Typography>
        <IconButton onClick={navigateToSystem} size="medium">
          <MdOpenInNew style={{ fontSize: 14, color: '#354A5F' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
}

function PreferenceOption({
  type,
  label,
  onChangeHandler,
  value,
  disabled = false,
  selectOptions,
  textFieldProps,
}) {
  switch (type) {
    case 'switch':
      return (
        <SwitchTypePreferenceOption
          value={value}
          onChangeHandler={onChangeHandler}
          label={label}
          disabled={disabled}
        />
      );

    case 'select':
      return (
        <SelectTypePreferenceOption
          value={value}
          onChangeHandler={onChangeHandler}
          label={label}
          disabled={disabled}
          selectOptions={selectOptions}
        />
      );

    case 'text':
      return (
        <TextTypePreferenceOption
          value={value}
          onChangeHandler={onChangeHandler}
          label={label}
          disabled={disabled}
          textFieldProps={textFieldProps}
        />
      );

    default:
      return null;
  }
}

function TextTypePreferenceOption({ value, onChangeHandler, label, disabled, textFieldProps }) {
  const { helperText, validator, ...rest } = textFieldProps;

  const isError = validator(value);

  return (
    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
      <Typography>{label}</Typography>
      <TextField
        size="small"
        type="number"
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
        helperText={isError && helperText}
        error={isError}
        {...rest}
      />
    </Stack>
  );
}

function SelectTypePreferenceOption({ value, onChangeHandler, label, disabled, selectOptions }) {
  return (
    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
      <Typography>{label}</Typography>
      <Select
        disabled={disabled}
        value={value}
        onChange={onChangeHandler}
        size="small"
        sx={{
          width: 125,
          height: 25,
          fontSize: 12,
        }}
        inputProps={{ sx: { height: 25 } }}
      >
        {Object.entries(selectOptions).map(([_, value]) => (
          <MenuItem
            key={value.value}
            value={value.value}
            sx={{
              fontSize: 12,
            }}
          >
            {value.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

function SwitchTypePreferenceOption({ value, onChangeHandler, label, disabled }) {
  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={value}
          onChange={onChangeHandler}
          color="primary"
          disabled={disabled}
        />
      }
      label={label}
      labelPlacement="start"
      sx={{ justifyContent: 'space-between', mx: 0 }}
    />
  );
}

const isPageSizeOutOfBounds = (pageSize) => {
  if (pageSize > PAGE_SIZE_DEFAULTS.UPPER_LIMIT || pageSize < PAGE_SIZE_DEFAULTS.LOWER_LIMIT) {
    return true;
  }
  return false;
};
