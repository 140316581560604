import { Stack } from '@mui/material';
import { useQueryBookmarks } from 'data/queriesAndBookmarks';
import { useDisclosure } from 'hooks/useDisclosure';
import { entries } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';

import {
  cancelAllPendingBatchedRequests,
  fetchPaginatedRecords,
  useIntializeIndexedDB,
  useWorkerStateMachine,
} from 'reportView/main';
import { BATCH_STATE } from 'reportView/utils';
import {
  QueryUrlProvider,
  useInitializeReportViewState,
  useReportViewState,
} from 'state/reportView';
import { ActionButtons } from './ReportViewComponents/ActionsButtons';
import { checkIfOneNMultiplicityPropertyIsSelected } from './ReportViewComponents/ExpandOption';
import ReportViewDrawer from './ReportViewComponents/ReportViewDrawer';
import { ReportViewToolbar } from './ReportViewComponents/ReportViewToolbar';
import TableArea from './ReportViewComponents/TableViewComponents/TableArea';
import QuerySummary from './ReportViewComponents/QuerySummary';
import {
  BOOKMARK_TYPE,
  FETCH_STATE,
  PAGE_SIZE,
  QUERY_STATE,
} from './ReportViewComponents/constants';
import { useConnection, useConnectionsAndSystems } from 'data/connectionsAndSystems';
import { PERMISSION_CODES, SYSTEMS } from '../constants';
import { ConnectionLoader } from './Loaders/ConnectionPageLoader';
import { BookmarkCreationDialog, useCreateBookmark } from './ReportViewComponents/BookmarkButton';
import { useTabs } from './SidePanel';
import { useCreateExportJob } from 'hooks/useCreateExportJob';
import CreateExportJobDialog from './ReportViewComponents/CreateExportJobDialog';
import { SchemaVisualizer } from './SchemaVisualizer';
import { useTenantState } from 'data/user';
import { FEATURE_CODES, useBillingUsage } from 'data/billingUsage';
import { useCurrentTabId } from './TabIndexedStorageContext';
import { purgeIDB } from 'reportView/worker';

// This hook records the keyHash value of all the primary records that have been fetched
const useKeyHashKeeper = (updateCustomPaginationValues) => {
  const [keyHashArray, setKeyHashArray] = useState({ entries: [], from: null, to: null });

  const addNewKeyHash = useCallback((transformedData) => {
    setKeyHashArray((state) => {
      const entries = state.entries;
      let from = null;
      let to = null;

      transformedData.forEach((t_data) => {
        if (!entries.includes(t_data.lf_dataflow_keyHash)) {
          entries.push(t_data.lf_dataflow_keyHash);
        }
      });

      if (transformedData.length === 1) {
        from = transformedData[0].lf_dataflow_keyHash;
        to = transformedData[0].lf_dataflow_keyHash;
      } else if (transformedData.length > 1) {
        from = transformedData[0].lf_dataflow_keyHash;
        to = transformedData[transformedData.length - 1].lf_dataflow_keyHash;
      }

      return {
        entries,
        from,
        to,
      };
    });
  }, []);

  const resetKeyHashKeeper = useCallback(() => {
    setKeyHashArray({ entries: [], from: null, to: null });
  }, []);

  useEffect(() => {
    updateCustomPaginationValues(keyHashArray);
  }, [keyHashArray, updateCustomPaginationValues]);

  return { addNewKeyHash, resetKeyHashKeeper };
};

export const initialQueryDataState = {
  key: nanoid(),
  data: [],
  error: null,
  queryState: QUERY_STATE.IDLE,
  fetchState: FETCH_STATE.IDLE,
};
const useFetchPaginatedData = ({
  pagination,
  updatePageNumber,
  updateTotalRecordsCount,
  isNMultiplicitySelected,
  addNewKeyHash,
}) => {
  const [queryData, setQueryData] = useState(initialQueryDataState);

  const batchState = useWorkerStateMachine();

  const fetchPaginatedDataFromDatabase = useCallback(
    async (page, shouldShowLoader = false) => {
      const limit = PAGE_SIZE;
      const skip = page * PAGE_SIZE;
      // The fetchPaginatedDataFromDatabase fn can be triggered manually aswell as automatically (useEffect)
      // It is triggered manually by using pagination or the "Apply" button. If the querying is happening
      // due to manual trigger we want to show a loader in the pagination. Hence we are using the QUERYING flag
      // When the user manaully triggers a query, the state is set to QUERYING, once the query is done, it is set to
      // SUCCESSFUL. The initial fetch will also trigger a state update
      if (shouldShowLoader) {
        setQueryData((state) => ({ ...state, queryState: QUERY_STATE.QUERYING }));
      }

      const { transformedData, queryState, totalRecords, error } = await fetchPaginatedRecords({
        limit,
        skip,
      })
        .then((paginatedData) => {
          if (paginatedData) {
            let transformedData = [];

            if (paginatedData.queryData.length > 0) {
              if (isNMultiplicitySelected) {
                let nestedTableData = {};
                let expandedPropertyNames;

                const primaryTableData = paginatedData.queryData.reduce((acc, record) => {
                  entries(record).forEach(([tableName, newRecord]) => {
                    if (!tableName.includes('nested_')) {
                      // find the expanded n-multiplicty navigation-properties
                      expandedPropertyNames = Object.entries(newRecord).reduce(
                        (acc, [propertyName, propertyData]) => {
                          if (Array.isArray(propertyData)) {
                            acc.push(propertyName);
                          }
                          return acc;
                        },
                        []
                      );

                      if (expandedPropertyNames) {
                        const expandedEntries = Object.fromEntries(
                          expandedPropertyNames.map((property) => [property, []])
                        );
                        const filteredRootPropertyData = {
                          ...newRecord,
                          ...expandedEntries,
                        };
                        if (
                          !acc.some(
                            (row) =>
                              row.lf_dataflow_keyHash ===
                              filteredRootPropertyData.lf_dataflow_keyHash
                          )
                        ) {
                          acc.push(filteredRootPropertyData);
                        }
                      }
                    } else {
                      // we are adding the below property because nested row data doesn't have information of the expand
                      // it is part of. In case of multiple n-multiplicity expands, we will require it for filtering in next steps.
                      newRecord._INT_navName = tableName.split('_').slice(2).join('_');
                      const keyHashValue = entries(newRecord).find(([propertyName, propertyData]) =>
                        propertyName.includes('lf_dataflow_keyHash')
                      )[1];

                      const existingData = nestedTableData[keyHashValue] ?? [];
                      nestedTableData[keyHashValue] = [...existingData, newRecord];
                    }
                  });

                  return acc;
                }, []);

                const mergedData = primaryTableData.map((data) => {
                  const nestedData = nestedTableData[data.lf_dataflow_keyHash] ?? [];

                  const separatedObjects = {};

                  expandedPropertyNames.forEach((string) => {
                    const filteredObjects = nestedData.filter((obj) => obj._INT_navName === string);
                    separatedObjects[string] = filteredObjects;
                  });

                  let newData = { ...data };
                  expandedPropertyNames.forEach((_INT_navName) => {
                    entries(separatedObjects).forEach(([tableName, record]) => {
                      if (_INT_navName === tableName) {
                        newData[_INT_navName] = [...record];
                      }
                    });
                  });

                  return newData;
                });

                // remove duplicate records - in case more than one n-multiplicity expand is selected
                if (expandedPropertyNames.length > 1) {
                  mergedData.forEach((obj) => {
                    for (const [key, value] of Object.entries(obj)) {
                      if (Array.isArray(value)) {
                        const uniqueIds = new Set();
                        obj[key] = value.filter(({ _INT_UQ_ID }) => {
                          if (_INT_UQ_ID !== undefined) {
                            if (uniqueIds.has(_INT_UQ_ID)) {
                              return false; // Remove duplicates
                            } else {
                              uniqueIds.add(_INT_UQ_ID);
                              return true;
                            }
                          }
                          return true; // Keep non-_INT_UQ_ID objects
                        });
                      }
                    }
                  });
                }

                transformedData = mergedData;
              } else {
                transformedData = paginatedData.queryData;
              }
            }

            addNewKeyHash(transformedData);
            return {
              transformedData,
              queryState: QUERY_STATE.QUERY_SUCCESSFUL,
              totalRecords: paginatedData.totalCount,
              error: null,
            };
          } else {
            return {
              transformedData: [],
              queryState: QUERY_STATE.IDLE,
              totalRecords: 0,
              error: null,
            };
          }
        })
        .catch((error) => {
          return {
            transformedData: [],
            queryState: QUERY_STATE.QUERY_FAILED,
            totalRecords: 0,
            error,
          };
        });

      setQueryData((state) => ({
        ...state,
        data: [...state.data, ...transformedData],
        queryState,
        error,
      }));

      updatePageNumber(page);
      updateTotalRecordsCount(totalRecords);

      return { data: transformedData, queryState, error };
    },
    [addNewKeyHash, isNMultiplicitySelected, updatePageNumber, updateTotalRecordsCount]
  );

  const noOfBatchesReady = useMemo(
    () => batchState.batches.filter((batch) => batch?.state === BATCH_STATE.READY).length,
    [batchState.batches]
  );

  const previousNoOfBatchesReady = usePrevious(noOfBatchesReady) ?? 0;
  useEffect(() => {
    if (previousNoOfBatchesReady !== noOfBatchesReady && noOfBatchesReady > 0) {
      // If the first batch is ready, then we want to make sure that we pass a true flag to fetchPaginatedDataFromDatabase,
      // so that they queryState is set to QUERYING and we can see a loader on the screen
      const shouldShowLoader = noOfBatchesReady === 1 ? true : false;
      fetchPaginatedDataFromDatabase(pagination.page, shouldShowLoader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfBatchesReady, pagination.page, previousNoOfBatchesReady]);

  return {
    fetchPaginatedDataFromDatabase,
    batchState,
    queryData,
    setQueryData,
  };
};

function useCloseOffPendingWorkerTasks() {
  useEffect(() => {
    return () => {
      // more cleanups can be added here in future
      cancelAllPendingBatchedRequests();
    };
  }, []);
}

const expandSliceSelector = (state) => ({
  expandFieldValues: state.current.expand.value,
  expandFieldOptionsMap: state.current.expand.optionsMap,
});
export function ReportView({
  schema,
  baseEndpoint,
  baseParams,
  isMetadataParsing,
  isMetadataLoading,
  isSchemaVisualizerOpen,
  toggleSchemaVisualizer,
  updateDiagramInstance,
}) {
  const tenant = useTenantState();
  const tabId = useCurrentTabId();

  const canCreateQuery = useMemo(
    () =>
      tenant?.permissions.some(
        ({ permission_code }) => permission_code === PERMISSION_CODES.CREATE_QUERY
      ),
    [tenant?.permissions]
  );

  const [disableToolbar, setDisableToolbar] = useState(false);
  const triggerDataFetchRef = useRef(() => {});

  const { refetchConnections } = useConnectionsAndSystems();

  // When Report View is loaded, we should fetch the list of connections again to ensure that
  // the connection information has not changed and the user still has access to the connection
  useEffect(() => {
    refetchConnections();
  }, [refetchConnections]);

  useEffect(() => {
    setDisableToolbar(!canCreateQuery);
  }, [canCreateQuery]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      purgeIDB({ tabId });
    };

    /* The beforeunload event is fired when the current window, contained document,
    and associated resources are about to be unloaded (during tab refersh).
    We ensure that indexed DB is cleared */
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [tabId]);

  const { connectionId } = useParams();
  const { selectedConnection: connection } = useConnection(connectionId, true);
  const systemType = connection?.user_system?.system;

  useInitializeReportViewState(schema, baseEndpoint, systemType);

  const { isOpen: isDrawerOpen, toggle: toggleDrawer, open: openDrawer } = useDisclosure();
  const { tabIndex: drawerTabIndex, updateTabIndex: updateDrawerTabIndex } = useTabs(0);

  const { expandFieldValues, expandFieldOptionsMap } = useReportViewState(expandSliceSelector);
  const isNMultiplicitySelected = checkIfOneNMultiplicityPropertyIsSelected(
    expandFieldValues,
    expandFieldOptionsMap
  );

  const {
    pagination,
    updatePageNumber,
    updateTotalRecordsCount,
    resetPagination,
    returnToFirstPage,
    updateCustomPaginationValues,
  } = useReportViewPagination();

  const { addNewKeyHash, resetKeyHashKeeper } = useKeyHashKeeper(updateCustomPaginationValues);

  const { fetchPaginatedDataFromDatabase, batchState, queryData, setQueryData } =
    useFetchPaginatedData({
      pagination,
      updatePageNumber,
      updateTotalRecordsCount,
      isNMultiplicitySelected,
      addNewKeyHash,
    });

  const isSfSystem = systemType === SYSTEMS.SF_EC.KEY || systemType === SYSTEMS.SF_EC_SHARED.KEY;

  const {
    bookmarkUrl,
    isOpen: isCreateBookmarkDialogOpen,
    open: openCreateBookmarkDialog,
    close: closeCreateBookmarkDialog,
  } = useCreateBookmark();

  const {
    isRefetching: isBookmarkRefetching,
    isLoading: isBookmarkLoading,
    data: bookmarkData,
    error: bookmarkError,
  } = useQueryBookmarks(connectionId, BOOKMARK_TYPE.REPORT_VIEW);

  // We make one call without passing the view in useQueryBookmarks, that will fetch all the bookmarks
  // via the bookmark endpoint. This is essential for cache updates that we do after 'moving', 'adding' or
  // 'deleting' bookmarks. This is to handle an edge case when report view is opened and used via deeplink.
  const { data: allBookmarksInConnection } = useQueryBookmarks(connectionId);

  const { isFeatureQuotaExhausted: isBookmarksQuotaExhausted } = useBillingUsage(
    FEATURE_CODES.BOOKMARK_CREATE_COUNT,
    allBookmarksInConnection
  );

  useCloseOffPendingWorkerTasks();

  // If the data is being fetched, we want to show the loader but only till there is some data to be shown.
  // If data.length>0, then we stop the loader. We also want to show a loader if we are running a query.
  // There could be scenarios where the data returned from query is 0 and the FETCHING is still going on. In this
  // scenario we have to check whether the QUERYING was successful or not
  const isFetchingData = queryData.fetchState === FETCH_STATE.FETCHING;
  const isLoadingData = queryData.data.length === 0 && isFetchingData;
  const hasDataLoaded =
    queryData.data.length > 0 &&
    (queryData.fetchState === FETCH_STATE.FETCHED || queryData.fetchState === FETCH_STATE.CANCELED);

  const isMetadataProcessing = isMetadataLoading || isMetadataParsing;

  const {
    isOpen: isExportJobDialogOpen,
    open: openExportJobDialog,
    close: closeExportJobDialog,
  } = useDisclosure();

  const { initializeDatabase } = useIntializeIndexedDB(schema);

  const exportUtilities = useCreateExportJob(openExportJobDialog, openDrawer, updateDrawerTabIndex);

  const { isCreatingJob, handleOpenExportJobDialog } = exportUtilities;

  const [pageNumber, setPageNumber] = useState(1);

  if (isSchemaVisualizerOpen) {
    return (
      <SchemaVisualizer
        metadata={schema}
        toggleSchemaVisualizer={toggleSchemaVisualizer}
        updateDiagramInstance={updateDiagramInstance}
      />
    );
  }

  if (isMetadataProcessing || !connection) {
    return (
      <ConnectionLoader
        isMetadataLoading={isMetadataLoading}
        isMetadataParsing={isMetadataParsing}
      />
    );
  }

  // if (!isChromium) {
  //   return (
  //     <Stack width="100%" height="100%" alignItems="center" justifyContent="center" spacing={1}>
  //       <UnsupportedBrowser height="30vh" />
  //       <Typography variant="h6" color="textSecondary" textAlign="center" sx={{ width: '55%' }}>
  //         This functionality is only supported in Google Chrome, Microsoft Edge, other chromium
  //         browsers. Please switch to a different browser to use this functionality
  //       </Typography>
  //     </Stack>
  //   );
  // }

  return (
    <QueryUrlProvider baseEndpoint={baseEndpoint} baseParams={baseParams}>
      <Stack height="100%">
        <Stack
          height="100%"
          sx={(theme) => ({
            width: isDrawerOpen ? `calc(100vw - ${theme.spacing(50)})` : '100%',
          })}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 12, p: 2, pb: 0 }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <ReportViewToolbar
                schema={schema}
                isSfSystem={isSfSystem}
                isLoadingData={isLoadingData}
                disableToolbar={disableToolbar}
                hasDataLoaded={hasDataLoaded}
                canCreateQuery={canCreateQuery}
              />
              <ActionButtons
                ref={triggerDataFetchRef}
                isLoadingData={isLoadingData}
                schema={schema}
                resetPagination={resetPagination}
                resetKeyHashKeeper={resetKeyHashKeeper}
                setQueryData={setQueryData}
                queryData={queryData}
                isSfSystem={isSfSystem}
                returnToFirstPage={returnToFirstPage}
                fetchPaginatedDataFromDatabase={fetchPaginatedDataFromDatabase}
                connection={connection}
                initializeDatabase={initializeDatabase}
                setPageNumber={setPageNumber}
                canCreateQuery={canCreateQuery}
              />
            </Stack>
          </Stack>
          <QuerySummary
            schema={schema}
            reportViewBookmarks={bookmarkData}
            isLoadingData={isLoadingData}
            openCreateBookmarkDialog={openCreateBookmarkDialog}
            openExportJobDialog={handleOpenExportJobDialog}
            isCreatingJob={isCreatingJob}
            openDrawer={openDrawer}
            updateDrawerTabIndex={updateDrawerTabIndex}
            isBookmarksQuotaExhausted={isBookmarksQuotaExhausted}
          />

          <TableArea
            ref={triggerDataFetchRef}
            pagination={pagination}
            fetchPaginatedDataFromDatabase={fetchPaginatedDataFromDatabase}
            batchState={batchState}
            queryData={queryData}
            setDisableToolbar={setDisableToolbar}
            baseEndpoint={baseEndpoint}
            setQueryData={setQueryData}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            canCreateQuery={canCreateQuery}
          />
        </Stack>
        <ReportViewDrawer
          schema={schema}
          reportViewBookmarks={bookmarkData}
          bookmarkError={bookmarkError}
          isBookmarkLoading={isBookmarkRefetching || isBookmarkLoading}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          baseEndpoint={baseEndpoint}
          systemType={systemType}
          openCreateBookmarkDialog={openCreateBookmarkDialog}
          setQueryData={setQueryData}
          tabIndex={drawerTabIndex}
          updateTabIndex={updateDrawerTabIndex}
          isCreatingJob={isCreatingJob}
          openDrawer={openDrawer}
          updateDrawerTabIndex={updateDrawerTabIndex}
          connection={connection}
        />
        {isCreateBookmarkDialogOpen && (
          <BookmarkCreationDialog
            isDialogOpen={isCreateBookmarkDialogOpen}
            closeDialog={closeCreateBookmarkDialog}
            bookmarkUrl={bookmarkUrl}
            reportViewBookmarks={bookmarkData}
          />
        )}
        {isExportJobDialogOpen && (
          <CreateExportJobDialog
            isExportJobDialogOpen={isExportJobDialogOpen}
            closeExportJobDialog={closeExportJobDialog}
            exportUtilities={exportUtilities}
            connection={connection}
          />
        )}
      </Stack>
    </QueryUrlProvider>
  );
}

const initialPaginationState = {
  page: 0,
  totalRecords: 0,
  // The below values are to handle the custom pagination we have implemented
  from: 0,
  to: 0,
};

function useReportViewPagination() {
  const [pagination, setPagination] = useState(initialPaginationState);

  const updatePageNumber = useCallback((page) => {
    setPagination((state) => ({ ...state, page }));
  }, []);

  const updateTotalRecordsCount = useCallback((totalRecords) => {
    setPagination((state) => ({ ...state, totalRecords }));
  }, []);

  const resetPagination = useCallback(() => {
    setPagination(initialPaginationState);
  }, []);

  const returnToFirstPage = useCallback(() => {
    setPagination((state) => ({ ...state, page: initialPaginationState.page }));
  }, []);

  const updateCustomPaginationValues = useCallback((keyHashArray) => {
    if (keyHashArray.entries.length === 0) {
      return;
    }
    setPagination((state) => ({
      ...state,
      from: keyHashArray.entries.indexOf(keyHashArray.from) + 1,
      to: keyHashArray.entries.indexOf(keyHashArray.to) + 1,
    }));
  }, []);

  return {
    pagination,
    updatePageNumber,
    updateTotalRecordsCount,
    resetPagination,
    returnToFirstPage,
    updateCustomPaginationValues,
  };
}
