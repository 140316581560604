/**
 * @file
 *
 * this file contains the custom hook to initialize global state for preferences.
 */

import { createStore } from '@halka/state';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';

import { DATAFLOW_PREFERENCES, LOCAL_STORAGE_KEYS, PAGE_SIZE_DEFAULTS } from '../constants';
import { togglePreference, wrapWithImmer } from 'utils';

const initialPreferencesState = {
  exportPreferences: {
    isPasswordMandatory: false,
  },
  queryPreferences: {
    showExternalCodes: false,
    pageSize: PAGE_SIZE_DEFAULTS.DEFAULT,
    selectAllFields: false,
  },
};

export const usePreferencesState = createStore(initialPreferencesState);
export const updatePreferencesState = wrapWithImmer(usePreferencesState.set);

export const useInitializePreferencesState = (connection) => {
  const dataflowPreferences = connection?.user_system?.preferences?.dataflow ?? {};

  const isPasswordMandatoryForExcelFile =
    dataflowPreferences[DATAFLOW_PREFERENCES.ENCRYPT_EXPORTED_FILE.KEY]?.is_enabled ?? true;

  const isSelectAllFieldsEnabled =
    dataflowPreferences[DATAFLOW_PREFERENCES.SELECT_ALL_FIELDS.KEY]?.is_enabled ?? false;

  const preferencesInLocalStorage = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.PREFERENCES) || '{}'
  );

  if (isEmpty(preferencesInLocalStorage)) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.PREFERENCES, JSON.stringify(initialPreferencesState));
  }

  useEffect(() => {
    updatePreferencesState((state) => {
      state.exportPreferences.isPasswordMandatory = isPasswordMandatoryForExcelFile;

      state.queryPreferences.selectAllFields = isSelectAllFieldsEnabled;
      state.queryPreferences.pageSize =
        preferencesInLocalStorage?.queryPreferences?.pageSize ?? PAGE_SIZE_DEFAULTS.DEFAULT;
      state.queryPreferences.showExternalCodes =
        preferencesInLocalStorage?.queryPreferences?.showExternalCodes ?? false;

      localStorage.setItem(LOCAL_STORAGE_KEYS.PREFERENCES, JSON.stringify(state));

      return state;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const preferencesStateHandlers = {
  updateIsPasswordMandatory: (newPreference, connection) => {
    updatePreferencesState((state) => {
      state.exportPreferences.isPasswordMandatory = newPreference;

      togglePreference({
        newValue: newPreference,
        connection,
        endpointPath: DATAFLOW_PREFERENCES.ENCRYPT_EXPORTED_FILE.API_ROUTE,
      });

      return state;
    });
  },

  updateShowExternalCodesInTable: (newPreference) => {
    updatePreferencesState((state) => {
      state.queryPreferences.showExternalCodes = newPreference;

      localStorage.setItem(LOCAL_STORAGE_KEYS.PREFERENCES, JSON.stringify(state));

      return state;
    });
  },

  updatePageSize: (newPageSize) => {
    updatePreferencesState((state) => {
      state.queryPreferences.pageSize = newPageSize;

      localStorage.setItem(LOCAL_STORAGE_KEYS.PREFERENCES, JSON.stringify(state));

      return state;
    });
  },

  updateSelectAllFields: (newPreference, connection) => {
    updatePreferencesState((state) => {
      state.queryPreferences.selectAllFields = newPreference;

      togglePreference({
        newValue: newPreference,
        connection,
      });

      return state;
    });
  },
};
